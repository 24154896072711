import { autoinject } from 'aurelia-framework';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ConfigType, ProgramConfig } from '@wsb_dev/datafi-shared/lib/types/ProgramConfig';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedProgramConfig } from '../../../types/ProgramConfig';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { defaultLayerObjects, defaultMap } from '../../../components/projects/data/defaultMap';
import { Router } from 'aurelia-router';
import { DirtyChecker } from '../../../types/DirtyChecker';
import { Program } from '@wsb_dev/datafi-shared/lib/types';
import { ILayerConfigMeta } from '../../../components/ol-map-config/ol-map-config';
import { IUrlParams } from '../../../components/ol-map/sources/dfpSourceUrlGeojson';
import { AppConfig } from '../../../services/util/AppConfig';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';

@autoinject
export class ConfigsEdit extends DirtyChecker {
    public dirtyProps: string[] = ['config'];
    configId: number;
    programId: number;
    config: ProgramConfig;
    program: Program;
    validationController: ValidationController;
    textField: HTMLElement;
    selectedVariableText: string;
    isModified = false;
    showAdvancedEditor = false;

    layerDefaults: Record<string, ILayerConfigMeta>;

    constructor(
        private api: DatafiProAPI,
        private validationFactory: ValidationControllerFactory,
        private alerts: AlertService,
        private router: Router,
        private appConfig: AppConfig,
    ) {
        super();
    }

    attached() {
        this.validationController = this.validationFactory.createForCurrentScope();
    }

    async activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        this.configId = ensureNumber(params.configId);

        this.program = await this.api.programs.get(this.programId, { query: { $eager: '[surveys,assetTypes]' } });
        this.config = await (this.configId ?
            this.api.programConfigs.get(this.configId).then((result) => new ValidatedProgramConfig(result)) :
            new ValidatedProgramConfig({
                program_id: this.programId,
                ...params.defaults,
                data: this.getDefaultsForStoreType(params.defaults?.type),
            })
        );

        if (!['ol-map', 'data-store'].includes(this.config.type)) {
            this.showAdvancedEditor = true;
        }

        if (this.config.type === 'ol-map') {
            this.populateMapLayers();
        }

        this.validationController = this.validationFactory.createForCurrentScope();
        this.updateClones();
    }

    populateMapLayers() {
        this.layerDefaults = {
            ...defaultLayerObjects,
            projectFeatures: {
                config: {
                    id: 'projectFeatures',
                    label: 'Project Features',
                    geometryType: 'polygon',
                    visible: true,
                    type: 'ol/layer/VectorImage',
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            baseUrl: this.appConfig.API_HOST,
                            typeId: 0,
                            serviceType: 'projects',
                            query: {},
                            programId: this.programId,
                        } as IUrlParams,
                    },
                },
                fields: [
                    ...flattenFields(getFields([
                        {
                            name: 'status',
                            options: [{value: 'Open'}, {value: 'Closed'}],
                        }],
                    )),
                    ...flattenFields(this.program.projectSchema)],
            },
        };
        this.program.surveys.forEach((s) => {
            this.layerDefaults[`surveys-${s.id}`] = {
                fields: flattenFields(s.surveySchema),
                config: {
                    id: `surveys-${s.id}`,
                    label: s.title,
                    type: 'ol/layer/VectorImage',
                    geometryType: 'point',
                    visible: false,
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            baseUrl: this.appConfig.API_HOST,
                            typeId: s.id,
                            serviceType: 'form-submissions',
                            query: {},
                            programId: this.programId,
                        } as IUrlParams,
                    },
                },
            } as ILayerConfigMeta;
        });

        this.program.assetTypes.forEach((a) => {
            this.layerDefaults[`assets-${a.id}`] = {
                fields: flattenFields(a.assetSchema),
                config: {
                    id: `assets-${a.id}`,
                    label: a.title,
                    geometryType: a.type,
                    visible: false,
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            baseUrl: this.appConfig.API_HOST,
                            typeId: a.id,
                            serviceType: 'programs-assets',
                            query: {},
                            programId: this.programId,
                        } as IUrlParams,
                    },
                },
            } as ILayerConfigMeta;
        });
    }

    getDefaultsForStoreType(type?: ConfigType) {
        if (!type) {
            return {};
        }

        const defaults: Partial<Record<ConfigType, any>> = {
            'data-store': {
                query: {
                    program_id: this.programId,
                },
                service: 'api/v1/form-submissions',
            },
            'ol-map': JSON.parse(JSON.stringify(defaultMap)),
        };

        return defaults[type] || {};
    }

    submit() {
        return this.validationController.validate()
            .then((result) => {
                if (result.valid) {
                    if (this.config.id) {
                        this.updateMapQuery();
                        return this.api.programConfigs.patch(this.config.id, this.config);
                    }

                    return this.api.programConfigs.create(this.config);
                }
            })
            .then((result) => {
                this.alerts.create({
                    label: 'Config updated',
                    level: 'success',
                    dismissable: true,
                });
                Object.assign(this.config, result);
                this.updateClones();
                this.router.navigateBack();
            })
            .catch((e) => {
                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            });

    }

    cancel() {
        this.router.navigateBack();
    }

    async updateMapQuery() {
        const result = await this.api.programConfigs.find({ query: { program_id: this.programId, type: 'dashboard' } }) as any;
        const dashboardsToUpdate = result.data.filter((dashboard) => {
            return (dashboard.data.items || [])
                .flatMap((item) => item.config?.mapOptions?.layers || [])
                .some((layer) => layer.id === `store-${this.config.id}`);
        });

        dashboardsToUpdate.forEach((dashboard) => {
            (dashboard.data.items || [])
                .flatMap((item) => item.config?.mapOptions?.layers || [])
                .filter((layer) => layer.id === `store-${this.config.id}`)
                .forEach((layer) => {
                    layer.source.url.query = JSON.parse(JSON.stringify(this.config.data.query));
                });
        });

        return await Promise.all(dashboardsToUpdate.map(async (dashboard) => {
            await this.api.programConfigs.patch(dashboard.id, dashboard);
        }));
    }

    populateConfigData(config: ProgramConfig): void {
        if (config.type && (!config.data || JSON.stringify(config.data) === '{}')) {
            config.data = this.getDefaultsForStoreType(config.type);
            if (JSON.stringify(config.data) !== '{}') {
                this.showAdvancedEditor = false;
            }
        }

        if (config.type === 'ol-map') {
            this.populateMapLayers();
        }
    }

    populateVariable(variableName: string) {
        if (!variableName) {
            return;
        }
        const newText = `{{ ${variableName} }}`;
        const el: HTMLTextAreaElement = this.textField.querySelector('textarea');
        const [start, end] = [el.selectionStart, el.selectionEnd];
        el.setRangeText(newText, start, end, 'select');
        this.selectedVariableText = '';
    }

}
